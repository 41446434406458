const 	$ = require('jquery'),
		PDFJS = require('pdfjs-dist'),
		worker = require('pdfjs-dist/build/pdf.worker.entry.js'),
		_ = require('underscore');

$(document).ready(function () {
	if($('#table_newsletters').length > 0) {
		$.get({
			url: './newsletters-scripts/main.php',
			data: { folder: '../news' },
			dataType: 'json',
			success: function(data) {
				var totalFiles = data.length;
				var files = [];

				if(data.length) {
					data.filter(function(x){
						if(x.type == 'pdf') {
							PDFJS.getDocument(x.url).then(doc => doc.getMetadata()).then(meta => {
								x.title = meta.info.Title;
								x.subject = meta.info.Subject;
								x.keywords = meta.info.Keywords;

								if(!x.keywords) {
									x.keywords = meta['metadata']['_metadata']['dc:subject'];
								}

								if(x.subject) {
									files.push(x);
								}

								totalFiles--;

							}, (err) => {
								console.log(err);
								totalFiles--;
							});
						}else {
							files.push(x);
							totalFiles--;
						}
					});
				}

				var c = setInterval(function(){
					if(totalFiles == 0) {
						clearInterval(c);

						var html = '';

						if(files.length) {
							files = _.sortBy(files, 'keywords').reverse();
							$.each(files, function(key, value) {
								html 	+= '<tr><td>'
										+  '<a href="' + value.url + '" target="_blank" rel="noopener noreferrer">' + value.subject + '</a>'
										+  '</td>'
										+  '<td class="data__date">'
										+  value.title
										+  '</td></tr>';
							});
						}else {
							html += '<td>No records found.</td>';
						}

						$('#table_newsletters tbody').html(html);
						enableClickableRows();
					}
				}, 50);
			}
		});
	}

	function enableClickableRows() {
		if($('#table_newsletters').length > 0) {
			var table_links = $('#table_newsletters tr a');

			for(var i = 0; i < table_links.length; i++) {
				var row = $(table_links[i]).parents('#table_newsletters tr');
					row.addClass('clickable');
					row.click(function() {
						var link = $(this).find('a')[0];
						window.open(link.href, link.target);
						event.preventDefault();
					});
			}
		}
	}
});